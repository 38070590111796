<template>
    <div id="mission">
        <div class="filter-panel">
            <CSSelect style="width: 170px; margin-right: 0;">
                <el-date-picker
                    v-model="searchTerm.startDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect style="width:170px">
                <el-date-picker
                    v-model="searchTerm.endDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <CSSelect >
                <select style="width:146px;" v-model="searchTerm.inventoryId">
                    <option selected value="">
                        全部盘点人
                    </option>
                    <option
                        v-for="inventory in inventoryPerList"
                        :value="inventory.id"
                        :key="inventory.id"
                    >{{inventory.name}}</option>
                </select>
            </CSSelect>
            <CSSelect>
                <select style="width:146px" v-model="searchTerm.inventoryState">
                    <option selected value="">
                        盘点状态不限
                    </option>
                    <option
                        v-for="inventoryState in isFinishList"
                        :value="inventoryState.id"
                        :key="inventoryState.id"
                    >{{inventoryState.name}}</option>
                </select>
            </CSSelect>
            <CSSelect>
                <select style="width:146px" v-model="searchTerm.inventoryResult">
                    <option selected value="">
                        盘点结果不限
                    </option>
                    <option
                        v-for="inventoryResult in resultStateList"
                        :value="inventoryResult.id"
                        :key="inventoryResult.id"
                    >{{inventoryResult.name}}</option>
                </select>
            </CSSelect>
            <button @click="getMission()" style="margin-left:10px;" type="button" class="btn btn-primary btn-sm">查询</button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm sticky-right"
                            @click="newInventoryInterface()"
                        >
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            盘点
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>盘点时间</th>
                        <th>盘点人</th>
                        <th>负责盘点物品</th>
                        <th>盘点状态</th>
                        <th>盘点结果</th>
                        <th>创建人</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr
                        :data-index="inventory.id"
                        v-for="(inventory,inventoryIndex) in inventoryList"
                        :key="inventoryIndex"
                    >
                        <td>{{inventory.taskTime}}</td>
                        <td>{{inventory.executantUserName}}【{{inventory.executantUserJobTitle}}】</td>
                        <td>
                            <span class="allow-click"
                                  @click="itemInterface(inventory.id,tabBarType)"
                            >{{inventory.checkCount}}</span>
                        </td>
                        <td>{{inventory.isFinishCode}}</td>
                        <td>
                            <span v-if="inventory.isFinish == 1">
                                {{inventory.finallyResult}}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <td>{{inventory.createUserName}}【{{inventory.createUserJobTitle}}】</td>
                        <td>
                            <button
                                v-if="inventory.isFinish == 1"
                                class="btn btn-primary btn-sm"
                                type="button"
                                @click="downloadMission(inventory)"
                            >
                                下载盘点详情</button>
                            <button
                                v-else
                                class="btn btn-primary btn-sm"
                                type="button"
                                @click="deleteMission(inventory)"
                            >删除</button>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination
                name="pagination"
                component="pagination"
            ></Pagination>
        </div>
        <!-- 盘点物品弹窗 -->
        <CSDialog
			class="tenant-detail-dialog"
            dialog-width="784px"
			:dialog-visible="itemInfo.visible"
			@onClose="itemInfo.visible = false"
			:dialog-show-confirm-btn="false"
			dialog-title="负责盘点物品"
			dialog-cancel-btn-text="关闭"
		>
			<template
				v-slot:dialog-content
			>
                <CSSelect style="width:145px;margin:30px 30px 0px 110px;">
                    <select>
                        <option selected value="">请选择盘点结果</option>
                        <option v-for="inventory in resultStateList"
                                :value="inventory.id"
                                :key="inventory.id"
                        >
                            {{inventory.name}}
                        </option>
                    </select>
                </CSSelect>
                <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        style="width:50px; margin-top:30px;"
                >
                    查询
                </button>
				<table style="margin:30px auto;" class="modal-inner-table">
					<thead>
						<tr style="height:54px;line-height:54px;">
							<th style="width:260px">物品名称</th>
							<th style="width:184px">实际库存数量</th>
							<th style="width:136px">库存数量</th>
							<th style="width:141px">盘点结果</th>
						</tr>
					</thead>
					<tbody>
						<tr style="height:64px;line-height:64px">
							<td>{{itemInfo.itemDetail.inventoryItem}}</td>
							<td>{{itemInfo.itemDetail.inventoryRealQuantity}}</td>
							<td>{{itemInfo.itemDetail.inventoryQuantity}}</td>
							<td>{{itemInfo.itemDetail.inventoryResult}}</td>
						</tr>
					</tbody>
				</table>
			</template>
		</CSDialog>
        <!-- 添加盘点弹窗 -->
        <Mission></Mission>
    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination";
import Mission from "@/components/warehouse/mission";
import {STORAGE_KEY} from "@/constant";

import {
    missionUrl,
    queryDepartmentStaffUrl,
    addMissonUrl,
    deleteMissionUrl,
    downloadMissionUrl,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
    components:{
        CSTable,
        CSSelect,
        CSDialog,
        Pagination,
        Mission,
    },
    data(){
        return {
            filterHeight: 0,
            isConfirm:false,
            isShow:false,
            tabBarType:3,
            newInventory:{},
            operatorId: this.$vc.getCurrentRegion().code,
            regionId: this.$vc.getCurrentRegion().communityId,
            searchTerm: {
                startDate: dayjs().subtract(1,"month").format("YYYY-MM-DD"),
			    endDate: dayjs().format("YYYY-MM-DD"),
                inventoryId: "",
                inventoryState: "",
                inventoryResult: "",
            },
            inventoryPerList:[],
            inventoryItemList:[],
            isFinishList:[
                {id:0,name:"未完成"},
                {id:1,name:"完成"},
            ],
            resultStateList:[
                {id:1,name:"盘盈"},
                {id:2,name:"盘亏"},
                {id:3,name:"持平"},
            ],
            inventoryList:[],
            itemInfo:{
                visible:false,
                itemid:null,
                itemDetail:{}
            }
        }
    },
    created(){
        window.addEventListener("keydown",this.getMissionDown);

        this.getMission();
        this.getStaff();
        this.$vc.on(this.$route.path, "mission","addInventoryDone",(data)=>{
            this.newInventory = data;
            this.addNewItem();
        });
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getMissionDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getMissionDown);
    },
    mounted(){
        this.$vc.on(this.$route.path, "pagination","page_event",(currentPage)=>{
            this.getMission(currentPage);
        })
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods:{
        getMissionDown(e){
            if(e.keyCode == 13){
                this.getMission();
            }
        },
        downloadMission(target){
            var id = target.id;
            this.$fly.get(downloadMissionUrl,{
                    id,
                },
                {
                    responseType: 'arraybuffer',
                    headers: {
                        notParse:true,
                    }
                }
            )
            .then(res=>{
                const blob = new Blob([res],{type: 'application/vnd.ms-excel'})
                const fileName = `${target.executantUserName}盘点任务.xlsx`;
                if("download" in document.createElement("a")){
                    const elink = document.createElement("a");
                    elink.download = fileName;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href);
                    document.body.removeChild(elink);
                }else{
                    navigator.msSaveBlob(blob,fileName);
                }
            })
        },
        deleteMission(target){
            var id = target.id;
            this.$CSDialog.warning({
                title:"提示",
                messageHtml:"确认删除吗?",
                onConfirm:()=>{
                    this.$fly.get(deleteMissionUrl,{
                        id,
                    })
                    .then(res=>{
                        if(res.code !== 0){
                            return;
                        }
                    })
                    setTimeout(()=>{
                        this.getMission();
                    },1000);
                    this.$CSDialog.instance.closeDialog();
                }
            })
        },
        getStaff(){
            this.$fly.post(queryDepartmentStaffUrl,{
                regionCode:this.$vc.getCurrentRegion().communityId,
                dutyType:""
            })
            .then(res=>{
                res.data.forEach(item=>{
                    if(this.$vc.isEmpty(item.resignTime)){
                        this.inventoryPerList.push(item);
                    }
                })
            })
        },
        getMission(pageNo = 1,pageSize = 10){
            this.$fly.post(missionUrl,{
                regionCode: this.regionId,
                startTime:this.searchTerm.startDate + ' 00:00',
                endTime:this.searchTerm.endDate + ' 23:59',
                executantUser:this.searchTerm.inventoryId,
                isFinish:this.searchTerm.inventoryState,
                result:this.searchTerm.inventoryResult,
                pageNo,
                pageSize,

            })
            .then(res=>{
                this.inventoryList = res.data.datas;
                this.inventoryList.forEach(state=>{
                    this.isFinishList.forEach(finish=>{
                        if(state.isFinish === finish.id){
                            state.isFinishCode = finish.name;
                        }
                    })
                    if(state.result !== undefined){
                        var result = JSON.parse(state.result);
                        state.result = [];
                        result.forEach(item=>{
                            for(var i = 0;i<this.resultStateList.length;i++){
                                if(item === this.resultStateList[i].id){
                                    state.result.push(this.resultStateList[i].name);
                                    if(state.result.length > 1){
                                        for(var i = 0; i < state.result.length-1;i++){
                                            state.finallyResult = state.result[i]+'/'+state.result[i+1]
                                        }
                                    }else{
                                        state.finallyResult = state.result[0]
                                    }
                                    break;
                                }
                            }
                        })
                    }
                    if(pageNo === 1){
                        this.$vc.emit(this.$route.path, "pagination","init",{
                            total:res.data.total || res.data.datas.length,
                            currentPage: pageNo,
                            pageSize,
                        })
                    }
                })
            })
        },
        itemInterface(id,tabBar){
            this.$router.push({
                name:"file",
                params:{
                    id,
                    tabBar
                }
            })
        },
        newInventoryInterface(){
            this.$vc.emit(this.$route.path, "mission","addInventory",true);
        },
        addNewItem(){
            this.$CSDialog.warning({
                title:"提示",
                messageHtml:"提交后将会通知盘点人，确认提交吗？",
                onConfirm:()=>{
                    this.$fly.post(addMissonUrl,{
                        regionCode:this.$vc.getCurrentRegion().communityId,
                        executantUser:this.newInventory.inventoryStaff,
                        taskTime:this.newInventory.startDate,
                        taskGoods:this.newInventory.inventoryItem
                    })
                    .then(res=>{
                        if(res.code !== 0){
                            return;
                        }
                        this.$CSDialog.instance.closeDialog();
                        this.getMission();
                    })
                }
            })
        }
    },
    destroyed(){
        sessionStorage.setItem(STORAGE_KEY.WAREHOUSE_TABID,0);
    }
}
</script>

<style lang="stylus">
.tenant-detail-dialog
  .el-dialog__header
    display none
  .preview-tenant
    color #000
    .field
      &-label
        width 200px
        display inline-block
        margin-right 40px
        text-align right
        vertical-align top
      &-content
        display inline-block
        max-width calc(100% - 240px)
  .el-divider__text.is-left
    font-size 24px
</style>
