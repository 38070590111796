<template>
    <div class="mission-dialog">
        <CSDialog
            class="tenant-detail-dialog"
            dialog-width="858px"
            :dialog-visible="inventoryInfo"
            @onClose="inventoryInfo = false"
            @onConfirm="jugement()"
            dialog-title="添加盘点"
        >
            <template
                v-slot:dialog-content
            >
                <div class="ibox-content">
                    <div class="ibox-item">
                        <p class="field">
                            <span
                                style="
                                    width:144px;
                                    margin-top:1px;
                                    display:inline-block;
                                    text-align:right;
                                    vertical-align:middle;
                                "
                                class="field-label">盘点人</span>
                            <CSSelect iWidth="36px" height="40px" style="margin-left:20px;">
                                <select v-model="newInventoryInfo.inventoryStation"
                                        style="width:295px;padding-left:5px;color: #999999"
                                        @change="getStationStaff(newInventoryInfo.inventoryStation)">
                                    <option selected value="">请选择</option>
                                    <option
                                        v-for="department in departmentList"
                                        :value="department.id"
                                        :key="department.id"
                                    >
                                        {{department.name}}
                                    </option>
                                </select>
                            </CSSelect>
                            <CSSelect iWidth="36px" height="40px" style="margin-top:1px;margin-left:20px;" class="margin-lable">
                                <select v-model="newInventoryInfo.inventoryStaff"
                                        style="width:295px;color: #999999"
                                        >
                                    <option selected value="">请选择</option>
                                    <option
                                        v-for="(staff,index) in departmentStaffList"
                                        :value="staff.id"
                                        :key="index"
                                    >
                                        {{staff.name}}
                                    </option>
                                </select>
                            </CSSelect>
                        </p>
                        <p id="timeField" class="field">
                            <span
                                style="
                                    width:144px;
                                    display:inline-block;
                                    text-align:right;
                                    vertical-align:middle;
                                "
                                class="field-label">盘点时间</span>
                            <CSSelect iWidth="36px" height="40px" style="margin-left:20px;">
                                <el-date-picker
                                    style="width:270px;font-size:24px"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    placeholder="请选择"
                                    v-model="newInventoryInfo.startDate"
                                    :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                        </p>
                        <div style="display:flex;align-items:flex-end;margin-top:1px;margin-bottom:10px;">
                            <label
                                style="
                                    width:144px;
                                    display:inline-block;
                                    margin-top:1px;
                                    text-align:right;
                                    vertical-align:middle;
                                "
                                class="field-label">负责盘点物品</label>
                            <ChooseLocation @missionItemList="getMission" mission="mission" style="margin-left:20px;--readonly-input-width: 270px"></ChooseLocation>
                        </div>
                        <span style="
                                    margin-top:1px;
                                    font-size:20px;
                                    color:#999999;
                                    line-height:28px;
                        ">
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            确定添加后，将无法修改信息，请仔细认真检查所填信息。
                        </span>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import ChooseLocation from "@/components/ChooseLocation";
import { ORG_LEVEL } from "@/constant";

import {
    queryDepartmentUrl,
    queryDepartmentStaffUrl,
    warehouseItemUrl,
} from "@/requestUrl"

export default {
    components:{
        CSDialog,
        CSSelect,
        ChooseLocation
    },
    data(){
        return {
            inventoryInfo:false,
            newInventoryInfo:{
                id:1,
                inventoryStation:"",
                inventoryStaff:"",
                startDate:"",
                inventoryItem:[],
            },
            inventoryItemList:[],
            departmentList:[],
            departmentStaffList:[],
        }
    },
    created(){
        this.$vc.on(this.$route.path, "mission","addInventory",(data)=>{
            this.inventoryInfo = data;
        })
        this.getDepartment();
        this.getItem();
        this.getMission();
    },
    methods:{
        getMission(data){
            this.newInventoryInfo.inventoryItem = data;
        },
        getItem(){
            this.$fly.post(warehouseItemUrl,{
                regionCode:this.$vc.getCurrentRegion().communityId,
                supplierId:"",
                state:1,
            })
            .then(res=>{
                this.inventoryItemList = res.data.datas || res.data;
            })
        },
        getDepartment(){
            this.$fly.post(queryDepartmentUrl,{
                regionCode:this.$vc.getCurrentRegion().code,
                level:ORG_LEVEL.DEPARTMENT,
            })
            .then(res=>{
                this.departmentList = res.data;
            })
        },
        getStationStaff(index){
            var stations = this.departmentList;
            for(var i = 0;i < stations.length;i++){
                if(stations[i].id === index){
                    var departmentCode = stations[i].code;
                    this.$fly.post(queryDepartmentStaffUrl,{
                        regionCode:this.$vc.getCurrentRegion().code,
                        departmentCode,
                    })
                    .then(res=>{
                        res.data.forEach(item=>{
                            if(!item.resignTime){
                                this.departmentStaffList.push(item)
                            }
                        })
                    })
                    break;
                }
            }
        },
        jugement(){
            if(this.jugementInventory()){
                this.$vc.emit(this.$route.path, "mission","addInventoryDone",this.newInventoryInfo);
                this.inventoryInfo = false;
                this.newInventoryInfo = {
                    id:1,
                    inventoryStation:"",
                    inventoryStaff:"",
                    startDate:"",
                    inventoryItem:"",
                }
            }
        },
        jugementInventory(){
            return this.$vc.validate.validate(
                {
                    newInventory:this.newInventoryInfo
                },
                {
                    "newInventory.inventoryStation":[
                        {
                            limit:"required",
                            param:"",
                            errInfo:"请选择部门"
                        }
                    ],
                    "newInventory.inventoryStaff":[
                        {
                            limit:"required",
                            param:"",
                            errInfo:"请选择员工"
                        }
                    ],
                    "newInventory.startDate":[
                        {
                            limit:"required",
                            param:"",
                            errInfo:"请选择盘点日期"
                        }
                    ],
                    "newInventory.inventoryItem":[
                        {
                            limit:"required",
                            param:"",
                            errInfo:"请选择盘点物品",
                        }
                    ]
                }
            )
        }
    },
    watch:{
        "newInventoryInfo.inventoryStation":function(){
            this.newInventoryInfo.inventoryStaff = "";
            this.departmentStaffList = [];
        }
    }
}
</script>

<style lang="stylus" scoped>
@media screen and (max-width:1400px){
    .margin-lable{
        margin-left: 163px !important;
        margin-top: 15px !important;
    }
}
.ibox-content
    font-size 24px
    color #444
    padding 30px
    .ibox-item
        line-height 1
        &:not(:last-of-type)
            margin-bottom 24px
        & > label
            width 175px
            text-align right
            font-size 24px
            margin-right 40px
            vertical-align middle
            margin-bottom 0
            &.top
                vertical-align top
        & > div
            display inline-block
            vertical-align middle
            position relative
            input, select
                border-radius 6px
                font-size 24px
                padding 0 10px
                box-sizing border-box
                height 40px
                border 1px solid #999
            input
                width 400px
/deep/.el-input__inner::placeholder
    color #999
</style>
